<template>
  <div class="body">
    <heads :more="showMore" :msg="title"></heads>
    <div class="lists">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoadDatas">
        <div class="item" v-for="(obj,idx) in lists" @click="goDetail(obj)">
          <div class="top">
            <div class="thumb">
              <img class="imgs" :src="obj.data.img" alt="">
            </div>
            <div class="titles">
              <p class="tit">
                {{ obj.data.title }}
              </p>
            </div>
          </div>
          <div class="bottom">
            <div class="numbers">
              <img class="viewIcon" src="@/assets/tupianku_view.png" alt="">
              <span class="viewNumber">{{ obj.data.view_num }}人浏览</span>
              <img class="replyIcon" src="@/assets/tupianku_reply.png" alt="">
              <span class="replyNumber">{{ obj.data.reply_num }}人跟帖</span>
            </div>
            <div class="user">
              <img class="avatar" :src="obj.data.avatar" alt="">
              <span class="uname">{{ obj.data.create_user }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import heads from '@/components/common/heads.vue'
import { searchPhotos } from '@/api/search.js'
export default {
  components: {
    heads
  },
  data () {
    return {
      title: this.$route.query.gname ? this.$route.query.gname : '图片库',
      gname: this.$route.query.gname ? this.$route.query.gname : '',
      q: this.$route.query.q ? this.$route.query.q : '',
      showMore: true,
      lists: [],
      loading: false,
      finished: false,
      page: 1,
      count: 0,
      limit: 20,
    }
  },
  created () {
  },
  methods: {
    // 获取数据
    getListDatas () {
      this.loading1 = true
      const post = {
        page: this.page,
        limit: this.limit,
        gname: this.gname,
        q: this.q
      }
      console.log(post);
      searchPhotos(post).then(res => {
        this.loading = false
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          const count = result.count ? result.count : 0
          this.count = count
          const list = result.list ? result.list : []
          this.lists = this.lists.concat(list)
          // 分页
          if (list.length < this.limit) {
            this.finished = true
          }
          else {
            this.page++
          }
        }
      })
    },
    // 分页
    onLoadDatas () {
      this.getListDatas()
    },
    // 去详情页
    goDetail (val) {
      window.location.href = val.data.url
    }
  }
}
</script>
<style lang="scss" scoped>
.lists {
  width: 100%;
  .item {
    padding: 30px 30px;
    border-bottom: 1px solid #eeeeee;
    .top {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      .thumb {
        .imgs {
          width: 220px;
          height: 134px;
          border-radius: 10px;
        }
      }
      .titles {
        width: calc(100% - 220px);
        .tit {
          margin-left: 20px;
          font-family: PingFangSC-Regular;
          font-size: 30px;
          color: #333333;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
    .bottom {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .numbers {
        display: flex;
        flex-direction: row;
        align-items: center;
        .viewIcon {
          width: 30px;
        }
        .viewNumber {
          margin-left: 8px;
          font-family: PingFangSC-Regular;
          font-size: 24px;
          color: #999999;
        }
        .replyIcon {
          margin-left: 32px;
          width: 30px;
        }
        .replyNumber {
          margin-left: 8px;
          font-family: PingFangSC-Regular;
          font-size: 24px;
          color: #999999;
        }
      }
      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        .avatar {
          width: 30px;
          display: block;
          border-radius: 15px;
        }
        .uname {
          margin-left: 10px;
          font-family: PingFangSC-Medium;
          font-size: 24px;
          color: #999999;
        }
      }
    }
  }
}
</style>
