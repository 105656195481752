import request from '@/utils/search_request.js'

// 搜索图库
export function searchPhotos(params) {
  return request({
    url: '/search/app_search/photo',
    methods: 'get',
    params
  })
}
