// import request from '@/utils/commonrequest'
import request from '@/utils/request_bbs'
// infohome页面头部企业信息头像接口
export function getagencyInfo(params) {
  return request({
    url: '/api/Agency/getAgencyInfo',
    methods: 'get',
    params
  })
}
export function getAppCommon(params) {
  return request({
    url: '/api/Agency/getAppCommon',
    methods: 'get',
    params
  })
}
